






















import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import Header from '~/components/Header.vue'
import Footer from '~/components/Footer.vue'

Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ['target']
  }
})

export default Vue.extend({
  components: {
    Header,
    Footer
  }
})
