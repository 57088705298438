import { render, staticRenderFns } from "./default.vue?vue&type=template&id=5e5ae262&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/vercel/path0/src/components/Header.vue').default,Footer: require('/vercel/path0/src/components/Footer.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules_dev/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VContainer})
